import { useCallback, useEffect, useState } from "react";
import Button from "../components/Button";
import Input from "../components/Input";
import { socket } from "../socket";
import Container from "../components/Container";
import Profile from "../components/Profile";
import Choice from "../components/Choice";
import GridCell from "../components/GridCell";

export default function Portrait(props) {
  const { username, display, setShield, debug } = props;

  const [roomInput, setRoomInput] = useState("");
  const [usernameInput, setUsernameInput] = useState(username || "");
  const [roomInputVisible, setRoomInputVisible] = useState(false);
  const [primaryColor, setPrimaryColor] = useState(
    localStorage.getItem("primary")
  );
  const [secondaryColor, setSecondaryColor] = useState(
    localStorage.getItem("secondary")
  );
  const [design, setDesign] = useState(Number(localStorage.getItem("design")));
  const [rotation, setRotation] = useState(
    Number(localStorage.getItem("rotation"))
  );

  const createRoom = useCallback(() => {
    socket.emit("create", {
      username: usernameInput
      // shield: {
      //   primaryColor,
      //   secondaryColor,
      //   design,
      //   rotation,
      // },
    });
  }, [usernameInput, primaryColor, secondaryColor, design, rotation]);

  useEffect(() => {
    if (debug) {
      socket.emit("debug", {
        username: usernameInput,
        // shield: {
        //   primaryColor,
        //   secondaryColor,
        //   design,
        //   rotation,
        // },
      });
    }
  }, [debug]);

  const joinRoom = useCallback(
    (room) => {
      if (room.length === 4) {
        console.log("Attempting to join room " + room);
        socket.emit("join", {
          username: usernameInput,
          room
          // shield: { primaryColor, secondaryColor, design, rotation },
        });
      }

      setRoomInputVisible(false);
      setRoomInput("");
    },
    [usernameInput, design, primaryColor, secondaryColor, rotation]
  );

  const showRoomCode = useCallback(() => {
    setRoomInputVisible(true);
  }, []);

  // const changePage = useCallback((newPage) => {
  //   setSubpage(newPage);
  // }, []);

  const changePrimaryColor = useCallback((newPrimary) => {
    setPrimaryColor(newPrimary);
    localStorage.setItem("primary", newPrimary);
  }, []);

  const changeSecondaryColor = useCallback((newSecondary) => {
    setSecondaryColor(newSecondary);
    localStorage.setItem("secondary", newSecondary);
  }, []);

  const changeDesign = useCallback((newDesign) => {
    setDesign(newDesign);
    localStorage.setItem("design", newDesign);
  }, []);

  const changeRotation = useCallback((newRotation) => {
    setRotation(newRotation);
    localStorage.setItem("rotation", newRotation);
  }, []);

  useEffect(() => {
    setShield({ primaryColor, secondaryColor, design, rotation });
  }, [setShield, primaryColor, secondaryColor, design, rotation]);

  return (
    <div
      className={` ${debug ? "flex" : "portrait:flex landscape:hidden"} bg-transition bg-cover bg-center relative flex-col items-center justify-end w-screen h-screen  
      py-20 z-10 select-none `}
    >
      {/* <GridCell xPos={1} yPos={1} xSize={2} ySize={1}>

      </GridCell>
      <GridCell xPos={1} yPos={10} xSize={2} ySize={1}>

      </GridCell> */}
      <div className={`h-[10%] text-white text-3xl bg-black/50 w-full flex justify-center items-center`}>
        Alpha Playtest
      </div>
      <Container
        justifyContent={"space-around"}
        tall={true}
        gap={"30px"}
      >
        <Container
          horizontal={true}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"5px"}
          wide={true}
        >
          <Button clickFunction={createRoom}>HOST</Button>
          <Input
            placeholder="ROOM"
            type="number"
            maximum={4}
            id="roomCode"
            value={roomInput}
            setValue={setRoomInput}
            display={roomInputVisible ? "block" : "none"}
            focus={roomInputVisible && roomInput === ""}
            method={joinRoom}
            bare={true}
          />
          {debug ? (<Button
            // onClick={showRoomCode}
            clickFunction={() => joinRoom("1234")}
            display={roomInputVisible ? "none" : "flex"}
          >
            JOIN
          </Button>) : (<Button
            clickFunction={showRoomCode}
            // onClick={() => joinRoom("1234")}
            display={roomInputVisible ? "none" : "flex"}
          >
            JOIN
          </Button>)}
          
        </Container>

        <Input
          placeholder="NAME"
          type="text"
          maximum={10}
          id="username"
          value={usernameInput}
          setValue={setUsernameInput}
        />

        {/* <Container
          justifyContent={ "center"}
          gap={"5px"}
        >
          <Container
            horizontal={true}
            justifyContent="center"
            background={true}
          >
            {[
              "red",
              "orange",
              "yellow",
              "green",
              "blue",
              "indigo",
              "violet",
            ].map((color, index) => (
              <Choice
                onClick={() => changePrimaryColor(color)}
                selected={
                  primaryColor === color || (!primaryColor && index === 0)
                }
                color={color}
                key={index}
              ></Choice>
            ))}
          </Container>
          <Container
            horizontal={true}
            justifyContent="center"
            background={true}
          >
            {[
              "white",
              "lightGray",
              "darkGray",
              "black",
              "lightBrown",
              "brown",
              "darkBrown",
            ].map((color, index) => (
              <Choice
                onClick={() => changeSecondaryColor(color)}
                selected={
                  secondaryColor === color || (!secondaryColor && index === 0)
                }
                color={color}
                key={index}
              ></Choice>
            ))}
          </Container>
          <Container
            horizontal={true}
            justifyContent="center"
            background={true}
          >
            {[0, 1, 2, 3, 4, 5, 6].map((d, index) => (
              <Choice
                onClick={() => changeDesign(d)}
                selected={design === d || (!design && index === 0)}
                design={d}
                key={index}
              ></Choice>
            ))}
          </Container>
        </Container> */}

        {/* <Container wide={true} halve={true} tall={true}>
          <Profile
            name={usernameInput}
            primary={primaryColor}
            secondary={secondaryColor}
            design={design}
            rotation={rotation}
            // display={orientation === "portrait" ? "flex" : "none"}
            onClick={() =>
              changeRotation(rotation !== 360 ? rotation + 45 : 45)
            }
            // onClick={changeRotation}
          ></Profile>
        </Container> */}
      </Container>
    </div>
  );
}
