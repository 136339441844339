import "./App.css";
import Background from "./components/Background.js";
import Longship from "./components/Longship.js";
import Notification from "./components/Notification.js";
import Waves from "./components/Waves.js";
import Game from "./pages/Game.js";
import Home from "./pages/Home.js";
import Intro from "./pages/Intro.js";
import Landscape from "./pages/Landscape.js";
import Lobby from "./pages/Lobby.js";
import Portrait from "./pages/Portrait.js";
import { socket } from "./socket";
import { useCallback, useEffect, useState } from "react";
// import ReactDOM from 'react-dom'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { fab } from '@fortawesome/free-brands-svg-icons'
// import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'

function App() {
  // library.add(fab, faCheckSquare, faCoffee)
  const debugMode = false;

  const [room, setRoom] = useState(null);
  const [users, setUsers] = useState(null);
  const [host, setHost] = useState(null);
  const [gameState, setGameState] = useState(null);
  const [shield, setShield] = useState(null);
  const [role, setRole] = useState(null);
  const [teammates, setTeammates] = useState([]);
  const [initialRole, setInitialRole] = useState(null);
  const [finalRole, setFinalRole] = useState(null);
  const [location, setLocation] = useState(null);
  const [info, setInfo] = useState(null);
  const [order, setOrder] = useState(null);
  const [votedRoles, setVotedRoles] = useState(null);
  const [finalRoles, setFinalRoles] = useState(null);
  const [votedUsers, setVotedUsers] = useState(null);
  const [timeLimits, setTimeLimits] = useState(null);
  const [entrancePhase, setEntrancePhase] = useState("start");
  const [entrancePhaseCount, setEntrancePhaseCount] = useState(1);
  const [entranceExiles, setEntranceExiles] = useState([]);
  const [entranceDead, setEntranceDead] = useState([]);
  const [entranceCooldown, setEntranceCooldown] = useState(0);


  const [roles, setRoles] = useState(null);


  function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const [username, setUsername] = useState( debugMode ? makeid(3) : localStorage.getItem("username") == null ? "GUEST" : localStorage.getItem("username"));
  // const [username, setUsername] = useState(localStorage.getItem("username"));



  // const getCookie = useCallback((cname) => {
  //   let name = cname + "=";
  //   let decodedCookie = decodeURIComponent(document.cookie);
  //   let ca = decodedCookie.split(";");
  //   for (let i = 0; i < ca.length; i++) {
  //     let c = ca[i];
  //     while (c.charAt(0) === " ") {
  //       c = c.substring(1);
  //     }
  //     if (c.indexOf(name) === 0) {
  //       return c.substring(name.length, c.length);
  //     }
  //   }
  //   return "";
  // }, []);


  const userJoin = useCallback(
    (username, roomCode, users, host) => {
      // console.log(username, " joined room ", roomCode)
      if (!room) {
        setRoom(roomCode);
        
        console.log("setroom", roomCode);
        //if this client isnt already in the room, set everything, otherwise only update users
        // console.log("Current user.", room, !room)
        setUsername(username);
        setHost(host);
        // setHost(users[0]["username"]);
        
        // console.log("host", users[0]["username"])
        setGameState("lobby");
      }
      setUsers(users);
      console.log(users);
    },
    [room]
  );

  const userReconnect = useCallback(
    (user, roomCode, users, host, gameState, timeLimits, phase, phaseCount, teammates, entranceExiles, entranceDead, currentCooldown) => {
      console.log("setroom", roomCode);
      setUsername(user["username"]);
      setHost(host);
      setRole(user["role"]);
      setRoom(roomCode);
      setTimeLimits(timeLimits);
      setUsers(users);
      setEntrancePhase(phase);
      setEntrancePhaseCount(phaseCount);
      setTeammates(teammates);
      setEntranceExiles(entranceExiles);
      setEntranceDead(entranceDead);
      setEntranceCooldown(currentCooldown);


      setGameState(gameState);

    }, []
  )

  const userLeave = useCallback(
    (user, users) => {
      if (user === username) {
        //if this client is leaving
        setRoom(null);
        console.log("setroom", null);
        setUsers(null);
        setHost(null);
        setGameState(null);
      } else {
        setUsers(users);
        console.log("users", users);
        console.log("user0", users[0]["username"]);
        console.log("host", host)
        // if (users[0]["username"] !== host) {
        //   setHost(users[0]["username"]);
        //   console.log("Host changed from " + user + " to " + username + ".");
        // }
      }
    },
    [username, host]
  );

  const changeHost = useCallback((newHost) => {
    console.log("Host changed from ", host, " to ", newHost)
    setHost(newHost);
  }, [host])

  const gameStart = useCallback((timeLimits) => {
    setTimeLimits(timeLimits);
    setGameState("game");
    localStorage.setItem("inGame", room);
    console.log("inGame", room)
  }, [room]);

  const gameEnd = useCallback(() => {
    setGameState("end");
    localStorage.setItem("inGame", null);
  }, []);

  const getRole = useCallback((role) => {
    setRole(role);
  }, []);

  const getInitialRole = useCallback((role) => {
    setInitialRole(role);
  }, []);

  const getLocation = useCallback((location) => {
    setLocation(location);
  }, []);

  // const updateUsers = useCallback((users) => {
  //   setUsers(users);
  // }, []);

  const getInfo = useCallback((info) => {
    setInfo(info);
  }, []);

  const getOrder = useCallback((order) => {
    setOrder(order);
  }, []);

  const getOverview = useCallback((dict) => {
    setVotedRoles(dict["votes"]);
    setFinalRoles(dict["roles"]);
    setVotedUsers(dict["users"]);
    console.log(dict["roles"][username]);
    setFinalRole(dict["roles"][username])
  }, [username])

  const toLobby = useCallback(() => {
    setGameState('lobby');
    setTeammates([]);
    setEntrancePhase("start");
    setEntrancePhaseCount(1);
    localStorage.setItem("inGame", null);
  }, []);

  const getTeammates = useCallback((teammates) => {
    setTeammates(teammates)
    console.log("getTeammates", teammates)
  }, [])

  const getRoles = useCallback((roles) => {
    setRoles(roles)
    console.log(roles)
    console.log(Object.values(roles))
  }, [])




  useEffect(() => {
    socket.on("room_create", userJoin);
    socket.on("user_leave", userLeave);
    socket.on("user_join", userJoin);
    socket.on("game_start", gameStart);
    socket.on("game_end", gameEnd);
    socket.on("get_role", getRole);
    socket.on("get_teammates", getTeammates);
    // socket.on("get_initial_role", getInitialRole);
    // socket.on("get_location", getLocation);
    // socket.on("update_users", updateUsers);
    socket.on("get_info", getInfo);
    // socket.on("get_order", getOrder);
    socket.on("overview", getOverview);
    socket.on("to_lobby", toLobby);
    socket.on("roles", getRoles);
    socket.on("user_reconnect", userReconnect);
    socket.on("change_host", changeHost);

    return () => {
      socket.off("room_create", userJoin);
      socket.off("user_leave", userLeave);
      socket.off("user_join", userJoin);
      socket.off("game_start", gameStart);
      socket.off("game_end", gameEnd);
      // socket.off("get_initial_role", getInitialRole);
      // socket.off("get_location", getLocation);
      // socket.off("update_users", updateUsers);
      socket.off("get_info", getInfo);
      // socket.off("get_order", getOrder);
      socket.off("overview", getOverview);
      socket.off("to_lobby", toLobby);
      socket.off("get_role", getRole);
      socket.off("get_teammates", getTeammates);
      socket.off("roles", getRoles);
      socket.off("user_reconnect", userReconnect);
      socket.off("change_host", changeHost);
    };
  }, [userJoin, userLeave, gameStart, gameEnd, getInfo, getOverview, getRole, getRoles, getTeammates, toLobby, userReconnect]);

  useEffect(() => {
    console.log("Connecting.");
    socket.connect();
    return () => {
      console.log("Disconnecting.");
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    // console.log("username changed to " + username);
    if (localStorage.getItem("inGame") !== null) {
      let disconnectedRoom = localStorage.getItem("inGame");
      console.log("dc room", disconnectedRoom)
      socket.emit("join", {
        username: username,
        room : disconnectedRoom
        // shield: null
      });
    }
  }, [username]);

  const getOrientation = () => window.screen.orientation.type;

  const [orientation, setOrientation] = useState(getOrientation());

  const updateOrientation = useCallback(
    (event) => {
      let newOrientation = getOrientation();

      setOrientation(
        newOrientation === "landscape-primary" ||
          newOrientation === "landscape-secondary"
          ? "landscape"
          : "portrait"
      );

    },
    []
  );

  useEffect(() => {
    window.addEventListener("orientationchange", updateOrientation);
    updateOrientation();
    return () => {
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, [updateOrientation]);

  const changeUsername = useCallback((newUsername) => {
    setUsername(newUsername);
    localStorage.setItem("username", newUsername);
  }, []);


  return (
    <div className="w-dvh h-dvh  flex flex-col items-center justify-center overflow-hidden relative select-none">
      {gameState ? (
        gameState === "lobby" ? (
          <Lobby
            username={username}
            room={room}
            users={users}
            host={host}
            orientation={orientation}
            roles = {roles}
          />
        ) : (
          <Game
          debugMode={debugMode}
            username={username}
            room={room}
            users={users}
            host={host}
            role={role}
            visibleTeammates={teammates}
            // initial_role={initialRole}
            votedRoles={votedRoles}
            votedUsers={votedUsers}
            // finalRole={finalRole}
            // finalRoles={finalRoles}
            info={info}
            // order={order}
            // location={location}
            timeLimits={timeLimits}
            roles={roles}
            entrancePhase={entrancePhase}
            entrancePhaseCount={entrancePhaseCount}
            entranceDead={entranceDead}
            entranceExiles={entranceExiles}
            entranceCooldown={entranceCooldown}
          />
        )
      ) : <div>
           <Portrait
             debug={debugMode}
             username={username}
             // display={orientation === "portrait" ? "flex" : "hidden"}
             setShield={setShield}
           />
           <Landscape users={users} host={host} shield={shield} debug={debugMode}></Landscape>
         </div>
      
      // username === "" || username === null ? (
        
      //   <Intro changeUsername={changeUsername} orientation={orientation} />
      // ) : (
      //   <div>
      //     <Portrait
      //       debug={debugMode}
      //       username={username}
      //       // display={orientation === "portrait" ? "flex" : "hidden"}
      //       setShield={setShield}
      //     />
      //     <Landscape users={users} host={host} shield={shield} debug={debugMode}></Landscape>
      //   </div>
      // )
      }

      <Notification></Notification>
    </div>
  );
}

export default App;
