import {
  faCross,
  faFlask,
  faHandBackFist,
  faHandHoldingHeart,
  faHandHoldingMedical,
  faHandPointLeft,
  faShield,
  faSkullCrossbones,
  faStar,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

export default function ActionButton(props) {
  const { text, clickFunction, action, active=true, visible } = props;

  const [buttonLayer, setButtonLayer] = useState(0);

  const actionSymbols = {
    voteOut: faThumbsDown,
    voteIn: faThumbsUp,
    attack: faHandBackFist,
    protect: faShield,
    poison: faFlask,
    exile: faHandPointLeft,
    return: faHandHoldingHeart,
    heal: faHandHoldingMedical,
    execute: faSkullCrossbones,
    resurrect: faCross,
    host: faStar,
    assassinate: faSkullCrossbones,
  };

  const statusColours = {
    green: "bg-green-900 text-green-600 border-green-600  ",
    neutral: "bg-neutral-800 text-neutral-500 border-neutral-500",
    red: "bg-red-900 text-red-600 ",
    orange: "bg-orange-900 text-orange-600 ",
  };

  // useEffect(() => {
  //   console.log("secondary buttons", secondaryButtons);
  // }, [secondaryButtons]);

  return (
    <div
      className={`w-full  h-min ${visible ? "flex" : "hidden"}  items-center justify-center flex-col text-nowrap gap-1 z-0 `}
    >
      <div
        className={`w-min h-min  ${
          active ? statusColours["green"] : statusColours["neutral"]
        } ${buttonLayer === 0 ? "flex" : "hidden"} items-center p-1 gap-1 justify-start rounded-3xl`}
        onClick={
          active ? clickFunction : null
          // secondaryButtons && secondaryButtons.indexOf(null) === -1
          //   ? () => setButtonLayer(buttonLayer + 1)
          //   : clickFunction
        }
      >
        <div
          className={`h-full aspect-square border-4  border-box flex justify-center items-center rounded-full p-2 ${
            active ? statusColours["green"] : statusColours["neutral"]
          }`}
        >
          <FontAwesomeIcon icon={actionSymbols[action ? action : "attack"]}></FontAwesomeIcon>
        </div>

        <div className={`h-full w-full flex justify-center items-center p-2`}>
          {text ? text.charAt(0).toUpperCase() + text.slice(1) : "Nothing"}
        </div>
      </div>

      {/* {buttonLayer !== 0 ? secondaryButtons.map((secondaryButton) => {
        return secondaryButton;
      }) : null} */}

    </div>
  );
}
